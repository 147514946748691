<template>
    <div>
        <div class="bg-sp-dark bottom-radius">
          <div class="container">
            <div class="row py-5">
              <div class="text-center text-md-start col-md-5 py-3">
                <h2>{{client.firstname}} <strong>{{familie.achternaamvol}}</strong></h2>
              </div>
            </div>
          </div>
       </div>
       <div id='body-grow' class="bg-sp-light overlay-radius">
          <div class="container">
            <div class="row">
                <div class="col-md-6 offset-md-5">
                    <div id='edit-card' class="card">
                        <div class="card-body">
                            <div class="mb-3">
                                <label for="voornaam" class="form-label">Voornaam<span>*</span></label>
                                <input class='form-control' id='voornaam' tabindex="1" v-model="client.firstname" placeholder="Voornaam" required='true' >
                            </div>
                             <div class="mb-3">
                                <label for="geboortedatum" class="form-label">Geboortedatum<span>*</span></label>
                                <input class='form-control' type='date' id='geboortedatum' tabindex="2" v-model.lazy="geboortedatum" required='true' placeholder="Geboortedatum">
                            </div>
                            <button
                                @click="saveForm()"
                                tabindex="8"
                                id='submit-button'
                                class="float-start float-md-end btn btn-primary btn-lg">
                                Opslaan
                            </button>
                        </div>
                    </div>
                </div>
              </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
import 'moment/locale/nl'
import { createToaster } from '@meforma/vue-toaster'

export default {
  name: 'PersoonBewerken',
  created: function () {
    this.moment = moment
    moment.locale('nl')
  },
  mounted () {
    window.scrollTo(0, 0)
    this.$store.dispatch('familie/getFamilie')
  },
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  methods: {
    async saveForm () {
      const toaster = createToaster({ position: 'bottom-left', duration: 3000 })
      this.$store.dispatch('familie/saveFamilieLid', this.id).then((res) => {
        if (res.status === 204) {
          toaster.show(`Account van ${this.client.firstname} opgeslagen`)
          this.$router.push({ name: 'Account' })
        } else {
          toaster.error('Error, kon niet opslaan')
        }
      })
    }
  },
  computed: {
    geboortedatum: {
      get: function () {
        return moment(this.client.birthdate).format('yyyy-MM-DD')
      },
      set: function (value) {
        this.$store.dispatch('familie/setBirthdayOfClient', {
          customerId: this.id,
          birthdate: value
        })
      }
    },
    client: function () {
      return this.$store.state.familie.info.clients.filter(client => {
        return client.customerId === this.id
      })[0]
    },
    familie () {
      return this.$store.state.familie ? this.$store.state.familie.info : false
    }
  }
}
</script>

<style scoped>
    .enlarge-container{
        margin-right: calc(-1 * var(--bs-gutter-x,.75rem));
        margin-left: calc(-1 * var(--bs-gutter-x,.75rem));
        background: var(--background-light);
        padding-right: var(--bs-gutter-x,.75rem);
        padding-left: var(--bs-gutter-x,.75rem);
        min-height: 100vh;
    }
    #dash-nav, #dash-content{
        background: var(--background-light)
    }
    .card{
        background: #fff;
        padding: 20px;
        margin-bottom: 1rem;
        border-radius: 20px;
        border: none
    }
    .form-control{
        padding: 12px
    }
    #body-grow{
      min-height: 80vh;
    }
    #edit-card{
        margin-top: -100px
    }
    @media screen and (max-width: 768px){
        #submit-button{
            width: 100%;
        }
        .card-body{
            padding: 0
        }
        .card{
            padding: 20px
        }
        #edit-card{
          margin-top: -3rem
      }
    }
  .form-label span{
      color: red;
  }
</style>
